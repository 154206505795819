import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/hook";
import MaskedInput from "react-text-mask";
import { useNavigate } from "react-router-dom";
import { stepReceved } from "../../../Redux/Store/auth/step";
import { authUserSagaAction, checkDigitsAction, loginUserSagaAction } from "../../../Redux/Saga/auth/authSagaActions";
import { getErrorsMessage } from "../../../Redux/Store/errorsMessage/errorsMessageSelector";
import { errorsMessageReceved } from "../../../Redux/Store/errorsMessage/errorsMessage";
import { Digits } from "../../../Types/auth/auth.type";
import styles from "./confirmation.module.scss";
import { GetUser, getStatusAuth } from "../../../Redux/Store/auth/selector";

const ConfirmationPage: FC = () => {
    const { control, handleSubmit, formState: { errors }, watch, reset, setFocus } = useForm<Digits>();
    const [timer, setTimer] = useState(59);
    const phone = useAppSelector(GetUser);
    const statusAuth = useAppSelector(getStatusAuth);
    // Сообщение об ошибке с сервера при вводе не правильного кода
    const errorsResponse = useAppSelector(getErrorsMessage);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // Для очистки сообщения с сервера
    useEffect(() => {
        watch(() => {
            dispatch(errorsMessageReceved(""));
        });
    }, [watch]);

    useEffect(() => {
        setFocus("digits");
    }, [ setFocus ])

    // Счетчик для повторного вызова
    useEffect(() => {
        setInterval(() => {
            setTimer((prevstate) => (prevstate >=1 ? prevstate - 1 : 0));
        }, 1000);
    }, []);

    const onSubmit = (data: Digits) => {
        dispatch(checkDigitsAction({
            digits: data.digits,
            // Передача navigate для роутинга при успешном запросе
            navigate: navigate
        }));
        reset();
    };

    const tryCall = () => {
        if (statusAuth === "login") {
            dispatch(loginUserSagaAction(phone));
            setTimer(59);
        };
        if (statusAuth === "regist") {
            dispatch(authUserSagaAction(phone));
            setTimer(59);
        };
    };

    return(
        <>
            <div className={styles.main}>
                <div className={styles.card}>
                    <h2>Код потверждения</h2>
                    <p>
                        Введите последние четыре цифры номера телефона, с которого вам поступил звонок.
                    </p>
                    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            control={control}
                            defaultValue={""}
                            name="digits"
                            rules={{ 
                                required: "Поле обязательно для заполнения",
                                minLength: {
                                    value: 4,
                                    message: "Введите код потдверждения"
                                }
                            }}
                            render={({
                                field: { onChange, value}
                            }) => (
                                <MaskedInput
                                    mask={[/\d/, /\d/, /\d/, /\d/]}
                                    placeholder=""
                                    guide={false}
                                    value={value}
                                    onChange={onChange}
                                    className={errors.digits ? styles.error_input : styles.input}
                                    autoFocus={true}
                                />
                            )}
                        />
                        {errors.digits &&
                            <p className={styles.error}>{errors.digits.message}</p>
                        }
                        {(errorsResponse && !errors.digits) &&
                            <p className={styles.error}>{errorsResponse}</p>
                        }
                    </form>
                    <div className={styles.buttons_list}>
                        <button className={styles.button} onClick={handleSubmit(onSubmit)}>Отправить</button>
                        <button
                            className={styles.button}
                            onClick={() => {
                                dispatch(stepReceved("auth"));
                                dispatch(errorsMessageReceved(""))}
                            }
                        >
                                Назад
                        </button>
                    </div>
                    <div style={{ width: "100%" }}>
                        {timer > 0 &&
                            <p  style={{ fontSize: 18 }}>Отправить повторный запрос можно через 60 секунд.</p>
                        }
                        {timer > 0 ?
                            (
                                <p>{timer >= 10 ? "0:" : "0:0"}{timer}</p>
                            )
                            :
                            (
                                <button className={styles.button + " " + styles.timer} onClick={tryCall}>Повторить звонок</button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmationPage;