import { FC } from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/hook";
import { useNavigate } from "react-router-dom";
import { stepReceved } from "../../../Redux/Store/auth/step";
import { getDefaultsCompany } from "../../../Redux/Store/auth/selector";
import { endLoginAction } from "../../../Redux/Saga/auth/authSagaActions";
import styles from "./endLoginPage.module.scss";

const EndLoginPage: FC = () => {
    const { control, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const defaultsCompany = useAppSelector(getDefaultsCompany);

    const onSubmit = (data: any) => {
        dispatch(endLoginAction({
            id_customer: data.company.id_customer,
            // Передача navigate для роутинга при успешном запросе
            navigate: navigate
        }));
    };

    return(
        <div className={styles.main}>
            <div className={styles.card}>
                <h2>Выбор пользователя</h2>
                <p>Есть несколько пользователей, связанных с этим номером. Пожалуйста, выберите акка-унт, в который вы хотите войти.</p>
                <form className={styles.forms} onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        control={control}
                        name="company"
                        rules={{ required: "Выберите часовой пояс" }}
                        render={({
                            field: { value, onChange }
                        }) => (
                            <Select
                                options={defaultsCompany}
                                value={value}
                                getOptionLabel={(label) => label.company_name}
                                getOptionValue={(value) => value.id_customer}
                                onChange={onChange}
                                className="react-select-container"
                                classNamePrefix={errors.company ? "react-error" : "react-select"}
                                isSearchable={false}
                                placeholder={errors.company ? "Выберите часовой пояс" : "Выбрать..." }
                                loadingMessage={() => "Загрузка..."}
                            />
                        )} 
                    />
                </form>
                <div className={styles.buttons_list}>
                    <button className={styles.button} onClick={handleSubmit(onSubmit)}>Войти</button>
                    <button className={styles.button} onClick={() => dispatch(stepReceved("confirmation"))}>Назад</button>
                </div>
            </div>
        </div>
    );
};

export default EndLoginPage;