import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../../Redux/Hooks/hook";
import { accountAddBalanceAction } from "../../../Redux/Saga/account/accountSagaActions";
import HeaderOptions from "../../Layouts/HeaderOptions/HeaderOptions";
import styles from "./addBalance.module.scss";
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { BASE_END_POINT, SERVER_URL } from "../../../Services/config";

type Balance = {
    balance: string
};

const AddBalance: FC = () => {
    const { register, handleSubmit, formState: { errors }, reset, setFocus } = useForm<Balance>();
    const [paymentMethod, setPaymentMethod] = useState<string>("paySber");
    const dispatch = useAppDispatch();

    // Оплата Сбербанк
    const paySber = (balance: string) => {
        dispatch(accountAddBalanceAction(balance));
        reset();
    };

    useEffect(() => {
        setFocus("balance");
    }, [ setFocus ]);

    // Выставление счета и загрузка PDF файла
    const payOrder = (balance: string) => {
        fetch(SERVER_URL + BASE_END_POINT, {
            method: "POST",
            headers: {
                "Content-type": "application/pdf"
            },
            body: JSON.stringify({
                action: "get_invoice",
                amount: balance
            }),
            credentials: "include"
        })
            .then(res => res.blob())
            .then(data => {
                let url = URL.createObjectURL(data);
                let anchor = document.createElement("a")
                anchor.href = url;
                document.body.append(anchor);
                anchor.click();
                anchor.remove();
                URL.revokeObjectURL(url);
            });
        reset();
    };

    const onPay = (data: Balance) => {
        if (paymentMethod === "paySber") {
            paySber(data.balance);
        } else if (paymentMethod === "payOrder") {
            payOrder(data.balance);
        };
    };

    return (
        <>
            <HeaderOptions stepOne="Личный кабинет" stepTwo="Пополнение баланса"/>
            <div className={styles.main}>
                <div className={styles.card}>
                    <h2>Пополнение баланса</h2>
                    <p>Введите сумму пополнения</p>
                    <form className={styles.form}>
                        <input
                            type="text"
                            defaultValue={""}
                            className={errors.balance ? styles.error_input : styles.input}
                            {...register("balance",{
                                required: "Поле обязательно к заполнению",
                                pattern: { value: /^\d+$/, message: "Введите только цифры"},
                            })}
                        />
                        {errors.balance && <p className={styles.error}>{errors.balance.message}</p>}
                    </form>
                    <FormControl>
                        <RadioGroup
                            defaultValue="paySber"
                            name="methodsPayment"
                            value={paymentMethod}
                            onChange={(event) => setPaymentMethod(event.target.value)}
                        >
                            <FormControlLabel value="paySber" control={<Radio color="default"/>} label={<Typography sx={{ fontSize: 18 }}>Оплата картой</Typography>} />
                            <FormControlLabel value="payOrder" control={<Radio color="default"/>} label={<Typography sx={{ fontSize: 18 }}>Оплата по счёту</Typography>} />
                        </RadioGroup>
                    </FormControl>
                    <div className={styles.buttons_list}>
                        <button className={styles.button} onClick={handleSubmit(onPay)}>Пополнить</button>
                    </div>
                </div>
            </div>
        </>
    );
};
 
export default AddBalance;