import { FC, useState } from "react";
import { useAppSelector } from "../../../Redux/Hooks/hook";
import { getListServices } from "../../../Redux/Store/services/selectorsServices";
import { useNavigate } from "react-router-dom";
import ModalAddServiceModal from "../../Ui/ModalWindows/ModalAddServiceInfo/ModalAddServiceInfo";
import elipseGrey from "../../../Assets/elipseGrey.svg";
import HeaderOptions from "../../Layouts/HeaderOptions/HeaderOptions";
import styles from "./addServicePage.module.scss";
import { getMainPage } from "../../../Redux/Store/mainUser/mainUserSelector";

const AddServicesPage: FC = () => {
    const [active, setActive] = useState(false);
    const navigate = useNavigate();
    const mainUser = useAppSelector(getMainPage);
    const listServices = useAppSelector(getListServices);

    const findActiveService = (id: string) => {
        const result = mainUser.list_service.find((item) => item.id_service === id);
        if (result) {
            return true;
        } else {
            return false;
        };
    };

    const onSumbit = (id: string) => {
        navigate(`/confirm/${id}`);
    };

    return(
        <>
            <HeaderOptions stepOne="Личный кабинет" stepTwo="Список услуг" stepThree="Подключить тариф"/>
            <main className={styles.main}>
                <h2>Услуги</h2>
                <p className={styles.info}>Для подключения услуги ваш баланс должен составлять не менее 100 рублей.</p>
                <div className={styles.list}>
                    {listServices.map((item) => (
                        <div className={styles.card} key={item.id_service}>
                            <div className={styles.title}>
                                <p>{item.service_name}</p>
                            </div>
                            <div className={styles.info}>
                                <div className={styles.text}>
                                    <img className={styles.elipse} src={elipseGrey} alt="точка"/>
                                    <p>до 5,000 авторизаций в месяц от 0,29 рубля в рамках тарифа</p>
                                </div>
                                <div className={styles.text}>
                                    <img className={styles.elipse} src={elipseGrey} alt="точка"/>
                                    <p>до 50,000 авторизаций в месяц от 0,25 рубля в рамках тарифа</p>
                                </div>
                                <div className={styles.text}>
                                    <img className={styles.elipse} src={elipseGrey} alt="точка"/>
                                    <p>до 500,000 авторизаций в месяц от 0,19 рубля в рамках тарифа</p>
                                </div>
                                <div className={styles.text}>
                                    <img className={styles.elipse} src={elipseGrey} alt="точка"/>
                                    <p>до 1,000,000 авторизаций в месяц от 0,15 рубля в рамках тарифа</p>
                                </div>
                            </div>
                            <button
                                className={findActiveService(item.id_service) ? styles.disabled_button : styles.button}
                                onClick={() => onSumbit(item.id_service)}
                                disabled={findActiveService(item.id_service) ? true : false}
                            >
                                {findActiveService(item.id_service) ? "Подключено" : "Подключить"}
                            </button>
                            <ModalAddServiceModal
                                name={item.service_name}
                                active={active}
                                setActive={setActive}
                            />
                        </div>
                    ))
                    }
                </div>
            </main>
        </>
    )
};

export default AddServicesPage;