import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/hook";
import { getServiceById } from "../../../Redux/Store/services/selectorsServices";
import { getMainPage } from "../../../Redux/Store/mainUser/mainUserSelector";
import { accountCreateActiveServiceAction } from "../../../Redux/Saga/account/activeServicesSaga/activeServicesActions";
import transformPhoneToString from "../../../Utils/transformPhoneToString";
import HeaderOptions from "../../Layouts/HeaderOptions/HeaderOptions";
import styles from "./confirmAddServicePage.module.scss";

const ConfirmAddServicePage: FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const service = useAppSelector(getServiceById(id));
    const user = useAppSelector(getMainPage);

    if (!service) {
        return (
            <div>
                ...Загрузка
            </div>
        );
    };

    const createActiveService = (id_service: string) => {
        dispatch(accountCreateActiveServiceAction({
            id_service: id_service,
            desc: "Токен",
            token_key: "",
            navigate: navigate
        }));
    };

    return(
        <>
            <HeaderOptions stepOne="Личный кабинет" stepTwo="Список услуг" stepThree="Подключить тариф" id={id}/>
            <main className={styles.main}>
                <h2>Потверждение</h2>
                <div>
                    <div className={styles.name}>Телефон: <p>{transformPhoneToString(user.tel_num)}</p></div>
                    <div className={styles.name}>Баланс: <p>{user.balance}</p></div>
                    <div className={styles.name}>Название услуги: <p>{service?.service_name}</p></div>
                </div>
                <button className={styles.button} onClick={() => createActiveService(service?.id_service)}>Потвердить подключение</button>
            </main>
        </>
    );
};

export default ConfirmAddServicePage;