import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../Redux/Hooks/hook";
import { Controller, useForm } from "react-hook-form";
import { accountAppendTelNumAction } from "../../../Redux/Saga/account/accountSagaActions";
import MaskedInput from 'react-text-mask'
import HeaderOptions from "../../Layouts/HeaderOptions/HeaderOptions";
import { Phone } from "../../../Types/auth/auth.type";
import convertPhoneNumber from "../../../Utils/transformTelNum";
import styles from "./AddNewPhonePage.module.scss";

const AddNewPhonePage: FC = () => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm<Phone>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onSubmit = (data: Phone) => {
        // Функция для преобразования номера телефона для отправки на сервер
        const phone = convertPhoneNumber(data.tel_num);
        dispatch(accountAppendTelNumAction({
            tel_num: phone,
            // Передача navigate для роутинга при успешном запросе
            navigate: navigate
        }));
        reset();
    };

    return(
        <>
            <HeaderOptions stepOne="Личный кабинет" stepTwo="Добавление номера" stepThree="Код"/>
            <main className={styles.main}>
                <form className={styles.card} onSubmit={handleSubmit(onSubmit)}>
                    <h2>Добавление номера телефена</h2>
                    <p>Введите номер телефона</p>
                    <div className={styles.form}>
                        <Controller
                            control={control}
                            defaultValue={""}
                            name="tel_num"
                            rules={{ 
                                required: "Поле обязательно для заполнения",
                                minLength: {
                                    value: 16,
                                    message: "Введите номер телефона"
                                }
                            }}
                            render={({
                                field: { onChange, value}
                            }) => (
                                <MaskedInput
                                    mask={["+","7",'(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                    placeholder="+7 (***) ***-**-**"
                                    autoFocus={true}
                                    guide={false}
                                    value={value}
                                    onChange={onChange}
                                    className={errors.tel_num ? styles.error_input : styles.input}
                                />
                            )}
                        />
                        {errors.tel_num && <p className={styles.error}>{errors.tel_num.message}</p>}
                    </div>
                    <button className={styles.button} onClick={handleSubmit(onSubmit)}>Добавить</button>
                </form>
            </main>
        </>
    );
};

export default AddNewPhonePage;