import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/hook";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { stepReceved } from "../../../Redux/Store/auth/step";
import { GetTimezZonesDefaults } from "../../../Redux/Store/timeZones/selectorsTimeZones";
import { createUserAction } from "../../../Redux/Saga/auth/authSagaActions";
import transormArraysToObject from "../../../Utils/transformArrays";
import styles from "./createUser.module.scss";

type Data = {
    name: string,
    company_name: string,
    time_zone: {
        value: string,
        label: string
    }
};

const CreateUserPage: FC = () => {
    const { control, register, handleSubmit, formState: { errors }, reset, setFocus } = useForm<Data>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const optionsTimeZones = useAppSelector(GetTimezZonesDefaults);
    const options = transormArraysToObject(optionsTimeZones);

    useEffect(() => {
        setFocus("name");
    }, [setFocus]);

    const onSubmit = (data: Data) => {
        dispatch(createUserAction({
            data: {
                name: data.name,
                company_name: data.company_name,
                time_zone: data.time_zone.value
            },
            // Передача navigate для роутинга при успешном запросе
            navigate: navigate
        }));
        reset();
    };

    return(
            <div className={styles.main}>
                <form className={styles.card} onSubmit={handleSubmit(onSubmit)}>
                    <h2>Настройка личного кабинета</h2>
                    <div className={styles.forms}>
                        <p>Введите ваше имя</p>
                        <input
                            defaultValue={""}
                            className={styles.input}
                            {...register("name", { required: "Поле обязательно для заполнения" })}
                        />
                        {errors.name && <p className={styles.error}>{errors.name.message}</p>}
                    </div>
                    <div className={styles.forms}>
                        <p>Введите название компании</p>
                        <input
                            defaultValue={""}
                            className={styles.input}
                            {...register("company_name", { required: "Поле обязательно для заполнения" })}
                        />
                        {errors.company_name && <p className={styles.error}>{errors.company_name.message}</p>}
                    </div>
                    <div className={styles.forms_select}>
                        <p>Выберите часовой пояс</p>
                        <Controller
                            control={control}
                            name="time_zone"
                            rules={{ required: "Выберите часовой пояс" }}
                            render={({
                                field: { value, onChange }
                            }) => (
                                <Select
                                    options={options}
                                    value={value}
                                    onChange={onChange}
                                    className="react-select-container"
                                    classNamePrefix={errors.time_zone ? "react-error" : "react-select"}
                                    isSearchable={false}
                                    placeholder={errors.time_zone ? "Выберите часовой пояс" : "Выбрать..." }
                                    loadingMessage={() => "Загрузка..."}
                                />
                            )} 
                        />
                    </div>
                    <div className={styles.buttons_list}>
                        <button className={styles.button} onClick={handleSubmit(onSubmit)}>Регистрация</button>
                        <button className={styles.button} onClick={() => dispatch(stepReceved("confirmation"))}>Назад</button>
                    </div>
                </form>
            </div>
    );
};

export default CreateUserPage;